<template>
  <vca-row>
    <h2>{{ current.full_name }}</h2>
    <div class="vca-right">
      <div
        class="tags-container"
        :class="{
          'tags-green': current.confirmed,
          'tags-orange': !current.confirmed,
        }"
      >
        <div v-if="current.confirmed" class="tag">
          <div class="tag-label">
            {{ $t("users.profile.confirmed.active") }}
          </div>
        </div>
        <div v-else class="tag">
          <div class="tag-label">
            {{ $t("users.profile.confirmed.inactive") }}
          </div>
          <div
            class="confirm"
            v-if="hasSystemPermission()"
            @click="confirmUser()"
          >
            &check;
          </div>
        </div>
      </div>
      <div v-if="isActive" class="tags-container tags-green">
        <div class="tag">
          <div class="tag-label">
            {{ $t("users.profile.active.active") }}
          </div>
          <div
            v-if="
              hasPoolPermission('network') ||
              hasPoolPermission('operation') ||
              hasSystemPermission()
            "
            class="cancel"
            @click="rejectActive()"
          >
            ✕
          </div>
        </div>
      </div>
      <div v-else-if="isRequested" class="tags-container tags-orange">
        <div class="tag">
          <div class="tag-label">
            {{ $t("users.profile.active.requested") }}
          </div>
          <div
            class="confirm"
            v-if="
              hasPoolPermission('network') ||
              hasPoolPermission('operation') ||
              hasSystemPermission()
            "
            @click="setActive()"
          >
            &check;
          </div>
          <div
            class="cancel"
            v-if="
              hasPoolPermission('network') ||
              hasPoolPermission('operation') ||
              hasSystemPermission()
            "
            @click="rejectActive()"
          >
            ✕
          </div>
        </div>
      </div>
      <div
        v-else-if="hasSystemPermission() && current.crew.id != ''"
        class="tags-container tags-white"
      >
        <div class="tag">
          <div class="tag-label">
            {{ $t("users.profile.active.inactive") }}
          </div>
          <div class="confirm" @click="setActive()">&check;</div>
        </div>
      </div>
      <div v-if="nvmActive" class="tags-container tags-green">
        <div class="tag">
          <div class="tag-label">
            {{ $t("users.profile.nvm.confirmed") }}
          </div>
          <div v-if="hasSystemPermission()" class="cancel" @click="removeNVM()">
            ✕
          </div>
        </div>
      </div>
      <div
        v-else-if="canBeNVM && hasSystemPermission()"
        class="tags-container tags-white"
      >
        <div class="tag">
          <div class="tag-label">
            {{ $t("users.profile.nvm.inactive") }}
          </div>
          <div class="confirm" @click="setNVM()">&check;</div>
        </div>
      </div>
      <div class="vertical-center">
        <button
          v-if="hasSystemPermission('admin')"
          @click="syncUser()"
          class="vca-button-small"
        >
          {{ $t("button.sync") }}
        </button>
      </div>
      <vca-cancel-button
        v-if="hasSystemPermission()"
        @click="deleteUser()"
        class="vertical-center"
        :placeholder="$t('users.profile.delete.button')"
      />
    </div>
  </vca-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileHeader",
  methods: {
    setActive() {
      if (!confirm(this.$t("users.profile.active.confirm"))) {
        return false;
      }
      this.$store.dispatch("users/active/accept").then(() => {
        this.current.active.status = "confirmed";
      });
    },
    rejectActive() {
      if (!confirm(this.$t("users.profile.active.reject"))) {
        return false;
      }
      this.$store.dispatch("users/active/reject").then(() => {
        this.current.active.status = "";
      });
    },
    setNVM() {
      if (!confirm(this.$t("users.profile.nvm.set"))) {
        return false;
      }
      this.$store.dispatch("user/nvm/set", this.current.id).then(() => {
        this.current.nvm.status = "confirmed";
      });
    },
    removeNVM() {
      if (!confirm(this.$t("users.profile.nvm.reject"))) {
        return false;
      }
      this.$store.dispatch("user/nvm/reject", this.current.id).then(() => {
        this.current.nvm.status = "rejected";
      });
    },
    confirmUser() {
      if (!confirm(this.$t("users.profile.confirmed.confirm"))) {
        return false;
      }
      this.$store.dispatch("users/confirm").then(() => {
        this.current.active.status = "confirmed";
      });
    },
    deleteUser() {
      if (!confirm(this.$t("users.profile.delete.confirm"))) {
        return false;
      }
      this.$store.dispatch("users/delete").then(() => {
        this.current = null;
      });
    },
    syncUser() {
      if (!confirm(this.$t("users.profile.sync.confirm"))) {
        return false;
      }
      if (this.current.profile.id == "") {
        this.$store.dispatch({ type: "users/update_profile" }).then(() => {
          this.$store.dispatch({
            type: "users/getById",
            data: this.current.id,
          });
        });
      } else {
        this.$store.dispatch("users/sync");
      }
    },
  },
  computed: {
    current: {
      get() {
        return this.$store.state.users.current;
      },
      set(value) {
        this.$store.commit("users/current", value);
      },
    },
    isActive() {
      return this.current.active.status == "confirmed";
    },
    canBeNVM() {
      const ret =
        this.current.active.status == "confirmed" &&
        this.current.profile &&
        this.current.profile.birthdate &&
        this.current.address_id != "";
      return ret;
    },
    nvmActive() {
      return this.current.nvm.status == "confirmed";
    },
    isRequested() {
      return this.current.active.status == "requested";
    },
    ...mapGetters({
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
