<template>
  <vca-column>
    <vca-row>
      <div>
        <h3>{{ $t("users.list.roles.header") }}</h3>
        <div>
          <vca-checkbox v-model="filter_all_roles" id="all">
            <span class="bold" v-if="!filter_all_roles">{{
              $t("users.list.roles.all")
            }}</span>
            <span class="bold" v-else>{{ $t("users.list.roles.none") }}</span>
          </vca-checkbox>
          <vca-checkbox
            v-for="role in roles"
            v-model="filter.pool_roles"
            :key="role"
            :id="role"
            >{{ $t("users.list.roles." + role) }}</vca-checkbox
          >
        </div>
      </div>
      <div>
        <div v-if="hasSystemPermission()">
          <h3>{{ $t("users.list.crew.header") }}</h3>
          <vca-dropdown
            v-model="crews"
            :options="crewList"
            label=""
            :placeholder="$t('users.list.crew.select.placeholder')"
          ></vca-dropdown>
        </div>
        <h3>{{ $t("users.list.status.header") }}</h3>
        <div class="vca-radiobutton">
          <input type="radio" v-model="filter.active_state" id="all" value="" />
          <label for="all"> {{ $t("users.list.status.all") }}</label>
        </div>
        <div class="vca-radiobutton">
          <input
            type="radio"
            v-model="filter.active_state"
            id="confirmed"
            value="confirmed"
          />
          <label for="confirmed">
            {{ $t("users.list.status.confirmed") }}</label
          >
        </div>
        <div class="vca-radiobutton">
          <input
            type="radio"
            v-model="filter.active_state"
            id="requested"
            value="requested"
          />
          <label for="requested">
            {{ $t("users.list.status.requested") }}</label
          >
        </div>
        <h3>{{ $t("users.list.nvm.header") }}</h3>
        <div>
          <vca-checkbox v-model="nvm_state">{{
            $t("users.list.nvm.status")
          }}</vca-checkbox>
        </div>
      </div>
    </vca-row>
    <div class="vca-center">
      <vca-cancel-button
        @click="reset()"
        :title="$t('context_button.reset', { 0: $t('context.filter') })"
        :placeholder="$t('context_button.reset', { 0: $t('context.filter') })"
      />
    </div>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "UsersFilter",
  data() {
    return {
      filter_all_roles: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  watch: {
    filter_all_roles: function (nVal) {
      this.filter.pool_roles = nVal ? this.roles : [];
    },
  },
  methods: {
    reset() {
      this.filter_all_roles = false;
      this.$emit("reset");
    },
  },
  computed: {
    filter: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    nvm_state: {
      set(value) {
        this.filter.nvm_state = value ? "confirmed" : "";
        this.$emit("input", this.filter);
      },
      get() {
        return this.filter.nvm_state == "confirmed";
      },
    },
    crews: {
      set(value) {
        this.filter.crew_id = value.map((el) => el.id);
        this.$emit("input", this.filter);
      },
      get() {
        const result = [];
        this.filter.crew_id.forEach((el) => result.push({ value: el }));
        return result;
      },
    },
    ...mapGetters({
      roles: "users/roles/list",
      crewList: "crews/dropdown",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
