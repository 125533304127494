<template>
  <vca-card>
    <ProfileHeader />
    <hr />
    <vca-row>
      <vca-card class="shadowed">
        <div class="vca-center avatar-container">
          <img :src="avatarURL" />
        </div>
      </vca-card>
      <ProfileData />
      <ProfileBulkRoles />
    </vca-row>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileHeader from "@/components/users/profile/ProfileHeader";
import ProfileData from "@/components/users/profile/ProfileData";
import ProfileBulkRoles from "./profile/ProfileBulkRoles.vue";
export default {
  name: "UserProfile",
  components: { ProfileHeader, ProfileData, ProfileBulkRoles },
  created() {
    this.$store.dispatch({
      type: "users/getById",
      data: this.current.id,
    });
  },
  computed: {
    ...mapGetters({
      current: "users/current",
    }),
    avatarURL() {
      if (this.current.avatar.file_id !== "") {
        return (
          process.env.VUE_APP_BACKEND_URL +
          "/users/avatar/img/" +
          this.current.avatar.file_id
        );
      } else {
        return "";
      }
    },
  },
};
</script>
