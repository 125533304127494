<template>
  <vca-card class="shadowed">
    <h3>{{ $t("users.profile.profile.header") }}</h3>
    <vca-row>
      <h4>{{ $t("users.profile.profile.email") }}</h4>
      <div>{{ current.email }}</div>
    </vca-row>
    <vca-row>
      <h4>{{ $t("users.profile.profile.mattermost") }}</h4>
      <div>{{ current.profile.mattermost_username }}</div>
    </vca-row>
    <vca-row>
      <h4>{{ $t("users.profile.profile.phone") }}</h4>
      <div>{{ current.profile.phone }}</div>
    </vca-row>
    <vca-row>
      <h4>{{ $t("users.profile.profile.age") }}</h4>
      <div class="vertical-center">
        {{ getAge(current.profile.birthdate) }}
      </div>
    </vca-row>
    <vca-row>
      <h4>{{ $t("users.profile.profile.gender") }}</h4>
      <div class="vertical-center">{{ current.profile.gender }}</div>
    </vca-row>
    <vca-row v-if="hasSystemPermission()">
      <div>
        <h3>{{ $t("users.profile.newsletter.header") }}</h3>
        <vca-row>
          <vca-checkbox
            :disabled="current.crew.id == ''"
            v-model="newsletter"
            @input="process('regional')"
            id="regional"
          >
            {{ $t("profile.newsletter.regional.title") }}
          </vca-checkbox>
          <vca-checkbox
            v-model="newsletter"
            @input="process('global')"
            id="global"
          >
            {{ $t("profile.newsletter.global.title") }}
          </vca-checkbox>
        </vca-row>
      </div>
    </vca-row>
    <vca-column>
      <h3>{{ $t("users.profile.address.header") }}</h3>
      <div v-if="hasSystemPermission('admin') && current.address_id">
        <vca-column>
          <vca-row>
            {{ current.address.street }}
            {{ current.address.number }}
          </vca-row>
          <vca-row v-if="current.address.additional">
            {{ current.address.additional }}
          </vca-row>
          <vca-row>
            {{ current.address.zip }}
            {{ current.address.city }}
          </vca-row>
          <vca-row>
            {{ current.address.country }}
          </vca-row>
        </vca-column>
      </div>
      <div v-else>
        <vca-row>
          <h4>
            {{ $t("users.profile.address.available") }}
          </h4>
          <span v-if="current.address_id">&check;</span>
          <span v-else>✕</span>
        </vca-row>
      </div>
    </vca-column>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileData",

  methods: {
    getAge(birthdate) {
      return birthdate
        ? new Date(new Date() - new Date(birthdate * 1000)).getUTCFullYear() -
            1970
        : "-";
    },
    process(value) {
      const newsletter = this.current.newsletter.find(
        (val) => val.value == value
      );
      if (newsletter != undefined) {
        this.$store
          .dispatch({
            type: "user/newsletter/delete",
            data: {
              id: newsletter.id,
              value,
            },
          })
          .then(() => {
            this.current.newsletter = this.current.newsletter.filter((el) => {
              return el.value != value;
            });
            this.$store.dispatch("refresh");
          });
      } else {
        this.$store
          .dispatch({
            type: "user/newsletter/create",
            data: {
              user_id: this.current.id,
              value: value,
            },
          })
          .then((response) => {
            this.$store.state.users.current.newsletter.push(response);
            this.$store.dispatch("refresh");
          });
      }
    },
  },
  computed: {
    current: {
      get() {
        return this.$store.state.users.current;
      },
      set(value) {
        this.$store.commit("users/current", value);
      },
    },
    newsletter: {
      get() {
        return this.current.newsletter
          ? this.current.newsletter.map((el) => {
              return el.value;
            })
          : []; //this.$store.state.users.current.newsletter
      },
      set(value) {
        return value;
      },
    },
    list: {
      get() {
        return this.$store.state.user.newsletter.list.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
