var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vca-card',{staticClass:"shadowed"},[_c('h3',[_vm._v(_vm._s(_vm.$t("users.profile.crew.header")))]),_c('vca-row',[_c('h4',{staticClass:"short"},[_vm._v(_vm._s(_vm.$t("users.profile.crew.crew")))]),_c('div',[_vm._v(_vm._s(_vm.current.crew.name))])]),_c('h3',[_vm._v(" "+_vm._s(_vm.$t("users.profile.applications.header"))+" "+_vm._s(_vm.user_participations.length)+" ")]),_c('vca-row',[_c('div',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t("users.profile.applications.confirmed")))]),_vm._v(" "+_vm._s(_vm.getParticipation("confirmed"))+" ")]),_c('div',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t("users.profile.applications.rejected")))]),_vm._v(" "+_vm._s(_vm.getParticipation("rejected"))+" ")])]),_c('vca-row',[_c('div',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t("users.profile.applications.requested")))]),_vm._v(" "+_vm._s(_vm.getParticipation("requested"))+" ")]),_c('div',[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.$t("users.profile.applications.withdrawn")))]),_vm._v(" "+_vm._s(_vm.getParticipation("withdrawn"))+" ")])]),_c('h4',[_vm._v(_vm._s(_vm.$t("users.profile.crew.roles")))]),(_vm.current.pool_roles && _vm.availableRoles.length > 0)?_c('div',{staticClass:"tags-container tags-white"},_vm._l((_vm.availableRoles),function(res){return _c('div',{key:res.name,staticClass:"tag",class:{ role_added: _vm.isAdded(res.value) }},[_c('div',{staticClass:"tag-label"},[_vm._v(_vm._s(res.name))]),(
          _vm.hasSystemPermission() ||
          _vm.hasPoolPermission('network') ||
          _vm.hasPoolPermission(res.value)
        )?_c('div',{staticClass:"confirm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addedRoles),expression:"addedRoles"}],staticClass:"role_checkbox",attrs:{"type":"checkbox"},domProps:{"value":res.value,"checked":Array.isArray(_vm.addedRoles)?_vm._i(_vm.addedRoles,res.value)>-1:(_vm.addedRoles)},on:{"change":function($event){var $$a=_vm.addedRoles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=res.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.addedRoles=$$a.concat([$$v]))}else{$$i>-1&&(_vm.addedRoles=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.addedRoles=$$c}}}})]):_vm._e()])}),0):(_vm.current.pool_roles == 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("users.profile.nvm.missing"))+" ")]):_vm._e(),(_vm.current.pool_roles)?_c('div',{staticClass:"tags-container tags-blue"},_vm._l((_vm.current.pool_roles),function(res){return _c('div',{key:res.name,staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(_vm._s(res.label))]),(
          _vm.hasSystemPermission() ||
          _vm.hasPoolPermission('network') ||
          _vm.hasPoolPermission(res.name)
        )?_c('div',{staticClass:"cancel"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentRoles),expression:"currentRoles"}],staticClass:"role_checkbox",attrs:{"type":"checkbox"},domProps:{"value":res.name,"checked":Array.isArray(_vm.currentRoles)?_vm._i(_vm.currentRoles,res.name)>-1:(_vm.currentRoles)},on:{"change":function($event){var $$a=_vm.currentRoles,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=res.name,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.currentRoles=$$a.concat([$$v]))}else{$$i>-1&&(_vm.currentRoles=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.currentRoles=$$c}}}})]):_vm._e()])}),0):_vm._e(),(_vm.availableRoles != 0 || _vm.current.pool_roles != 0)?_c('div',[_c('button',{staticClass:"vca-button-small",on:{"click":_vm.updateRoles}},[_vm._v(" "+_vm._s(_vm.$t("context_button.update", { 0: _vm.$t("context.roles") }))+" ")])]):_vm._e(),_c('h3',[_vm._v(_vm._s(_vm.$t("users.profile.system.roles")))]),(_vm.current.system_roles)?_c('div',{staticClass:"tags-container"},_vm._l((_vm.current.system_roles),function(res){return _c('div',{key:res.name,staticClass:"tag"},[_c('div',{staticClass:"tag-label"},[_vm._v(_vm._s(res.label))])])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }